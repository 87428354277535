<template>
  <div class="event">
    <h1>{{ this.$t("event.event") }}</h1>
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "Event",
};
</script>

<style scoped>
.event {
  display: block;
  width: 1200px;
  max-width: 100%;
  margin: 0 auto;
  padding: 1rem;
}
.event > h1 {
  font-size: 1.6rem;
  font-weight: 700;

  padding: 0;
  margin: 0 0 2rem 0;
}
</style>
